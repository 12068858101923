@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&&display=swap');

:root {
   --dark: #34383f;
   --normal: #7b8faf;
   --light: #eaf0f9;
   --text: #444;
   --spacing: 0.7rem;
   --spacing-half: 0.4rem;
   --spacing-double: 1.4rem;
}

h1,
h2,
p,
button,
input,
select {
   display: block;
   margin: 0 0 var(--spacing) 0;
}

h1 {
   font-size: 2rem;
   font-weight: 500;
}

h2 {
   font-size: 1.2rem;
   font-weight: 500;
}

label {
   display: inline-block;
   margin-bottom: 0.2rem;
}

label.inline {
   display: inline-block;
   vertical-align: middle;
   margin-bottom: 0;
}

button,
.button,
input,
select {
   box-sizing: border-box;
   padding: var(--spacing);
   border: 0.15rem solid var(--light);
   border-radius: 0.1rem;
   outline: none;
   background-color: white;
   font: inherit;
   color: inherit;
}

button,
.button {
   display: inline-block;
   margin-right: var(--spacing);
   cursor: pointer;
}

input,
select {
   max-width: 25rem;
   width: 100%;
}

input.inline {
   display: inline-block;
}

input[type="color"],
input[type="checkbox"] {
   display: inline-block;
   vertical-align: middle;
   margin-top: var(--spacing);
}

input[type="color"] {
   height: 2rem;
   width: 2rem;
   padding: 0;
   border: none;
   margin-left: var(--spacing);
}

input[type="checkbox"] {
   width: 1rem;
   height: 1rem;
   margin: var(--spacing-double) var(--spacing) var(--spacing-double) var(--spacing);
}

input:focus,
select:focus {
   border-color: var(--normal);
}

html,
body,
#root {
   height: 100%;
}

body {
   margin: 0;
}

#root {
   font-family: 'Poppins', sans-serif;
   color: var(--text);
   display: grid;
   grid-template-areas: "header" "main" "footer";
   grid-template-rows: auto 1fr;
}

#root>header {
   grid-area: header;
}

#root>footer {
   grid-area: footer;
}

#root>header,
#root>footer {
   padding: var(--spacing);
   background-color: var(--dark);
   color: white;
}

#root>header>div,
#root>footer>div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: auto;
}

#root>header nav ul {
   margin: 0;
   padding: 0;
   list-style: none;
}

#root>header h1 a,
#root>header nav a {
   color: white;
   text-decoration: none;
}

#root>header nav a {
   opacity: 0.7;
}

#root>header nav a:hover {
   opacity: 0.9;
}

#root>header h1 a.active,
#root>header nav a.active {
   opacity: 1;
   pointer-events: none;
}

#root>header>div,
#root>main,
#root>footer>div {
   box-sizing: border-box;
   max-width: 75rem;
   padding: var(--spacing);
}

@media (min-width: 80rem) {
   #map {
      max-height: 60rem;
   }
}

#root>header h1 {
   margin-bottom: 0;
}

#root>footer p {
   margin: 0;
}

#root>main {
   width: 100%;
   justify-self: center;
   grid-area: main;
   display: flex;
   flex-direction: column;
   position: relative;
   overflow-y: auto;
}

.landing {
   flex-direction: row !important;
   flex-wrap: wrap;
   align-content: flex-start;
   align-items: center;
}

.landing div {
   padding: 1rem;
}

.landing img {
   width: 100%;
}

.landing h1 {
   margin-bottom: 2rem;
}

.landing p {
   line-height: 2;
}

.landing .button {
   text-decoration: none;
}

#map {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   box-sizing: border-box;
   border: 0.15rem solid var(--light);
   border-radius: 0.1rem;
   margin: 0 !important;
}

#map>div {
   width: 100%;
   height: 100%;
}

#map .delete-point {
   padding: var(--spacing-half);
   margin: 0;
}

.map-overlays {
   margin: 0 !important;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   visibility: hidden;
}

.map-overlays>* {
   position: absolute;
   visibility: visible;
}

.map-headline {
   left: 0;
   top: 0;
   flex-direction: row !important;
}

.map-headline h2 {
   padding: var(--spacing);
   border: 0.15rem solid var(--light);
   border-radius: 0.1rem;
   background-color: white;
}

.map-headline>* {
   margin: var(--spacing) 0 0 var(--spacing);
}

.map-overlays .overlay {
   box-sizing: border-box;
   background-color: white;
   height: 100%;
   width: 100%;
   padding: var(--spacing);
   z-index: 1;
}

.map-overlays>:not(.overlay) {
   display: flex;
   flex-direction: column;
}

.map-overlays>:not(.overlay) button {
   line-height: 1;
}

.location-controls {
   right: 0;
   bottom: 0;
}

.map-controls {
   right: 0;
   top: 0;
}

.map-controls button {
   margin-bottom: 0;
   margin-top: var(--spacing);
}

.map-object-controls {
   left: 0;
   bottom: 0;
}

.map-object-controls button {
   margin-left: var(--spacing);
   margin-right: 0;
}

div.category {
   display: flex;
}

div.category input[type="text"] {
   flex-grow: 1;
   margin-right: var(--spacing);
}

@media (min-width: 40rem) {
   label {
      margin-bottom: 0;
      margin-right: var(--spacing);
   }

   input,
   select {
      display: inline-block;
      margin-right: var(--spacing);
   }

   .landing > div {
      padding: 3rem 1rem;
      width: 50%;
      box-sizing: border-box;
   }

   .map-overlays {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
   }

   .map-overlays>* {
      position: static;
   }

   .map-overlays .overlay {
      position: absolute;
      padding-bottom: 0;
      height: auto;
   }

   .map-overlays>:not(.overlay) {
      flex-direction: row;
      margin-left: var(--spacing-double);
   }

   .map-overlays>:not(.overlay) button {
      margin: var(--spacing) 0 0 var(--spacing);
   }
}